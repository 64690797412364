import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginForm from '../views/LoginForm.vue'
import Main from '../views/Main.vue'
import Payment from '../views/Payment.vue'
import PedidoPago from '../views/PedidoPago.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'main',
    component: Main,
    redirect: "/login",
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginForm
      },
      {
        path: 'payment',
        name: 'payment',
        component: Payment,
      },
      {
        path: 'payment-ok',
        name: 'payment-ok',
        component: PedidoPago
      },
    ]
  },
  {
    path: "*",
    redirect: "/"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
