var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper",style:(`
    height: ${_vm.size}px;
    width: ${_vm.size * (2 + _vm.repeat / 100)}px;
  `)},[_vm._l((_vm.repeat),function(n,index){return [(_vm.src)?_c('v-img',{key:n,staticClass:"image",style:({
        transform: `translateX(${-50 - (_vm.repeat - 1) * 10}%)`,
        left: `${50 + index * 10}%`,
        'z-index': _vm.repeat - index,
      }),attrs:{"width":_vm.size,"height":_vm.size,"src":_vm.src}}):_c('v-icon',{key:n,staticClass:"image",style:({
        transform: `translateX(-50%)`,
        left: `${50 + index * 10}%`,
        'z-index': _vm.repeat - index,
      }),attrs:{"size":_vm.size}},[_vm._v(" mdi-book ")])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }