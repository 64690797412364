
import Component from "vue-class-component";
import Mixins from "./Mixins.vue";
import { productStore } from "./store";

@Component
export default class App extends Mixins {
  get cssProps() {
    var themeColors = {};
    Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
      themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
    });
    return themeColors;
  }

  updateFavIcon(newLink: string) {
    var link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = newLink;
  }

  async mounted() {
    // @ts-ignore
    if (!this.isKNN) {
      await productStore.getProducts({ franchising_id: 1069 });

      window.document.title = "Loja - Phenom Idiomas";
      this.$vuetify.theme.themes.light.primary = "#FF7A00";
      this.$vuetify.theme.themes.light.secondary = "#EE1077";
      this.updateFavIcon(
        "https://www.phenomidiomas.com.br/wp-content/uploads/2021/08/cropped-android-chrome-192x192-1-32x32.png"
      );
    } else {
      await productStore.getProducts({ franchising_id: 1 });

      window.document.title = "Loja - KNN Idiomas";
      this.$vuetify.theme.themes.light.primary = "#645FC9";
      this.$vuetify.theme.themes.light.secondary = "#F70052";
      this.updateFavIcon(
        "https://www.knnidiomas.com.br/wp-content/uploads/2021/08/cropped-favicom-knn-32x32.png"
      );
    }
  }
}
