
import Mixins from "@/Mixins.vue";
import Component from "vue-class-component";

@Component
export default class LogoImg extends Mixins {
  get socialMedia() {
    return {
      website: {
        icon: "mdi-web",
        iconColor: "primary",
        baseUrl: this.isKNN
          ? "https://www.knnidiomas.com.br"
          : "https://www.phenomidiomas.com.br",
        text: this.isKNN ? "knnidiomas.com.br" : "phenomidiomas.com.br",
      },
      facebook: {
        icon: "mdi-facebook",
        iconColor: "#4267B2",
        baseUrl: "http://www.facebook.com/",
        value: this.isKNN ? "knnidiomasoficial" : "phenom.idiomas",
      },
      instagram: {
        icon: "mdi-instagram",
        iconColor: "#D62976",
        baseUrl: "http://www.instagram.com/",
        value: this.isKNN ? "knnidiomasbrasil" : "phenom.idiomas",
      },
      twitter: {
        icon: "mdi-twitter",
        iconColor: "#00acee",
        baseUrl: "http://www.twitter.com/",
        value: this.isKNN ? "knn_brasil" : "phenomidiomas",
      },
      youtube: {
        icon: "mdi-youtube",
        iconColor: "#c4302b",
        baseUrl: "http://www.youtube.com/",
        value: this.isKNN ? "knnidiomasbrasil" : "@phenom.idiomas",
      },
    };
  }
}
