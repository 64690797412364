import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import "@/plugins/sweet-alert"
import "./plugins/v-mask";
import { format, parseISO } from "date-fns"



Vue.config.productionTip = false
Vue.filter("formatPrice", (value: string | number) => {
  if (value) {
    const numberValue = Number(String(value).replace(/[\D+\.+?]/g, ""))
    return `R$ ${numberValue.toFixed(2)}`
  }
});

Vue.filter("formatDate", (value: string, _format: string = "dd/MM/yyyy HH:mm") => {
  return format(parseISO(value), _format)
});

Vue.filter('formatDateTimeWithoutConvert', (value: string) => {
  if (value) {
    value = value.replace('Z', '');
    const value2 = new Date(value);
    return format(value2, 'dd/MM/yyyy HH:mm');
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
