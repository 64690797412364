import Vue from "vue";
import Vuex, { Store } from "vuex";
import { initialiseStores, modules } from '@/utils/store-acessor'

Vue.use(Vuex)

const initializer = (store: Store<any>) => initialiseStores(store)

export const plugins = [initializer]

export * from '../utils/store-acessor'

export default new Store({
  plugins,
  modules,
});
