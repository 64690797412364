import Vue from 'vue';
import Vuetify from "vuetify";


import "@mdi/font/css/materialdesignicons.css";
import pt from "vuetify/src/locale/pt";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#645FC9",
                secondary: "#F70052",
                tertiary: "#306bff",
                text: "#0E0823",
                gray100: "#E6EDF4",
                success300: "#C2F2D8",
                success900: "#257A4C",
                blue500: "#645FC9",


            }
        }
    },
    lang: {
        locales: { pt },
        current: "pt",
    },
});
