
import Mixins from "@/Mixins.vue";
import { invoiceAlunoStore } from "@/store";
import { Component } from "vue-property-decorator";
import LogoImg from "../components/LogoImg.vue";

@Component({
  components: { LogoImg },
})
export default class Main extends Mixins {
  get show() {
    return this.$router.currentRoute.path === "/login" || !!this.invoiceAluno;
  }

  get invoiceAluno() {
    return invoiceAlunoStore.invoiceAluno;
  }

  async mounted() {
    await this.checkLoggedIn();
  }
}
