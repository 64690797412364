
import Mixins from "@/Mixins.vue";
import Component from "vue-class-component";
import DialogComponent from "../components/DialogComponent.vue";

@Component({
  components: {
    DialogComponent,
  },
})
export default class PrivacyData extends Mixins {
  htmls = {
    TermoUso: "",
    PoliticaPrivacidade: "",
  };

  async mounted() {
    await fetch("./termos_uso.html").then(async (response) => {
      this.htmls.TermoUso = await response.text();
      if (!this.isKNN) {
        this.htmls.TermoUso = this.htmls.TermoUso.replace(/KNN/g, "Phenom");
        this.htmls.TermoUso = this.htmls.TermoUso.replace(/knn/g, "phenom");
      }
    });

    await fetch("./politica_privacidade.html").then(async (response) => {
      this.htmls.PoliticaPrivacidade = await response.text();
      if (!this.isKNN) {
        this.htmls.PoliticaPrivacidade = this.htmls.PoliticaPrivacidade.replace(
          /KNN/g,
          "Phenom"
        );
        this.htmls.PoliticaPrivacidade = this.htmls.PoliticaPrivacidade.replace(
          /knn/g,
          "phenom"
        );
      }
    });
  }
}
