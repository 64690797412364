import { render, staticRenderFns } from "./LogoImg.vue?vue&type=template&id=5d47b39b&scoped=true"
import script from "./LogoImg.vue?vue&type=script&lang=ts"
export * from "./LogoImg.vue?vue&type=script&lang=ts"
import style0 from "./LogoImg.vue?vue&type=style&index=0&id=5d47b39b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d47b39b",
  null
  
)

export default component.exports