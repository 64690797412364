import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import InvoiceModule from '@/store/modules/invoice'
// import MainModule from '@/store/modules/main'
import ProductModule from '@/store/modules/product'
import InvoiceAlunoModule from '@/store/modules/invoiceAluno'

// let mainStore: MainModule;
let invoiceStore: InvoiceModule;
let productStore: ProductModule;
let invoiceAlunoStore: InvoiceAlunoModule;

function initialiseStores(store: Store<any>): void {
    // mainStore = getModule(MainModule, store)
    invoiceStore = getModule(InvoiceModule, store)
    productStore = getModule(ProductModule, store)
    invoiceAlunoStore = getModule(InvoiceAlunoModule, store)
}

export const modules = {
    // main: MainModule,
    invoice: InvoiceModule,
    product: ProductModule,
    invoiceAluno: InvoiceAlunoModule,
}

export {
    initialiseStores, invoiceStore, productStore, invoiceAlunoStore
}
