
import { addSeconds, differenceInSeconds, format } from "date-fns";
import Component from "vue-class-component";
import { Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class TimerComponent extends Vue {
  @Prop({ type: Number, default: 300 }) duration!: number;

  now = new Date();
  nowInterval: any = null;

  endTime: Date | null = null;

  get remainingTime() {
    if (!this.endTime) return;
    const secondsRemaining = differenceInSeconds(this.endTime, this.now);
    const time = new Date(secondsRemaining * 1000);
    return format(time, "mm:ss");
  }

  @Emit("end")
  onTimerEnd() {
    return true;
  }

  mounted() {
    this.endTime = addSeconds(new Date(), this.duration);
    this.nowInterval = setInterval(() => {
      this.now = new Date();
      if (this.now > this.endTime) {
        clearInterval(this.nowInterval);
        this.onTimerEnd();
      }
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.nowInterval);
  }
}
