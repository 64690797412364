
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class BookCover extends Vue {
  @Prop({ type: String, default: null }) readonly src!: string;
  @Prop({ type: Number, default: 1 }) readonly repeat!: number;
  @Prop({ type: Number, default: 50 }) readonly size!: number; // Fixed type from String to Number
}
