
import Mixins from "@/Mixins.vue";
import { Component } from "vue-property-decorator";
import LogoImg from "../components/LogoImg.vue";
import { invoiceStore } from "../store";

@Component({
  components: {
    LogoImg,
  },
})
export default class PedidoPago extends Mixins {
  passosError = [
    "- Verifique se preencheu os dados do cartão corretamente;",
    "- Verifique se possui limite disponível;",
    "- Verifique se o cartão está bloqueado para cobranças no crédito;",
    "- Entre em contato com seu banco;",
    "- Pague com PIX.",
  ];

  get loggedEmail() {
    return localStorage.getItem("invoice-login");
  }

  get currentUserPedido() {
    return invoiceStore.lastInvoice;
  }

  closeSection() {
    localStorage.clear();
    this.$router.push("/");
    invoiceStore.clearPedido();
  }

  async mounted() {
    if (!this.currentUserPedido) {
      this.$router.push("/");
    }
  }
}
