
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ProgressBar extends Vue {
  @Prop({ default: "20" }) readonly height!: string;
  @Prop({ default: "80" }) readonly size!: string;
  @Prop({ default: "Carregando..." }) readonly text!: string;
}
