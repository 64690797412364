
import Mixins from "@/Mixins.vue";
import { invoiceStore } from "@/store";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";

@Component
export default class Coupon extends Mixins {
  @Prop({ type: String, default: "" }) readonly couponApplied!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  isOpen: boolean = false;
  isLoading: boolean = false;
  coupon: string = "";

  @Emit("input")
  async applyCoupon() {
    this.isLoading = true;
    const { confirmed, data } = await invoiceStore.verifyCoupon({
      couponCode: this.coupon,
      companyId: this.lastInvoice.company.id,
      // preInvoiceId: this.lastInvoice.id!,
    });
    this.isLoading = false;
    if (!confirmed) {
      this.$swal({
        title: data,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        returnFocus: true,
      });
      return { confirmed: false };
    } else {
      this.isOpen = false;
      return { confirmed: true, couponCode: this.coupon };
    }
  }

  closeCoupon() {
    this.coupon = "";
    this.isOpen = false;
  }
}
