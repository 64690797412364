
import Mixins from "@/Mixins.vue";
import { invoiceAlunoStore, invoiceStore } from "@/store";
import _ from "lodash";
import { Component } from "vue-property-decorator";

declare interface FormFields {
  email: string;
  password: string;
}

@Component
export default class LoginForm extends Mixins {
  get invoiceAluno() {
    return invoiceAlunoStore.invoiceAluno;
  }

  get isDisabled() {
    return !this.form.email || !this.form.password;
  }

  get lastInvoice() {
    return invoiceStore.lastInvoice;
  }

  get btnClass() {
    return this.lastInvoice || this.loadingData
      ? "loadingButton"
      : this.isDisabled
      ? "disabledButton"
      : "coloredButton";
  }

  isHidden = true;
  hasError = false;
  showSnackbar = false;
  loadingData = false;
  snackbarMsg = "";

  form = {
    email: "",
    password: "",
  } as FormFields;

  async onSubmit(form: FormFields) {
    this.loadingData = true;
    const { isOk, msg } = await invoiceAlunoStore.getInvoiceAluno({
      email: form.email,
      password: form.password,
    });

    this.hasError = !isOk;
    this.snackbarMsg = msg;
    this.showSnackbar = true;
    this.loadingData = false;

    if (isOk) {
      await this.checkLoggedIn();
    }
  }

  async mounted() {
    const params: any = _.get(this.$router.currentRoute, "query", {});
    const { email, password } = params;
    if (email && password) {
      this.form.email = email;
      this.form.password = password;
      await this.onSubmit(this.form);
    }
  }
}
