const env = process.env.VUE_APP_ENV;

let envApiUrl = "";
let appNameEnv = "";


if (env == "production") {
  const hostname = window.location.hostname.replace("loja", "master")
  envApiUrl = `https://${hostname}/api/v1`;
  appNameEnv = '';
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}/api/v1`;
  appNameEnv = 'dev';
}

// Mecanismo para rodar Master no mobile
// envApiUrl = 'http://10.80.16.59:8000'
// appNameEnv = 'dev';

export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME + ' ' + appNameEnv;
export const API_USER_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE4ODA1NTYwNjEsInN1YiI6IjMxNiJ9.N6FoFalDq9w7pbLYBFyREJ-2_H7owefvoFxNZW77_f8"