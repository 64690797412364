import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import api from "@/api"
import _ from 'lodash'
import { IProduct } from '@/interfaces/product'


@Module({ name: "product" })
export default class ProductModule extends VuexModule {

  products: IProduct[] = []

  @Mutation
  setProducts(payload: IProduct[]) {
    this.products = payload
  }

  @Action
  async getProducts(payload?: { franchising_id: number }) {
    try {
      const response = await api.getProducts(payload)
      this.setProducts(response.data)
    } catch (e) {
    }
  }
}