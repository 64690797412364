
import { Component, Vue } from "vue-property-decorator";
import { invoiceAlunoStore, invoiceStore } from "./store";

@Component
export default class Mixins extends Vue {
  get invoiceAluno() {
    return invoiceAlunoStore.invoiceAluno;
  }

  get url() {
    return window.location.href;
  }

  get host() {
    return window.location.host;
  }

  get loginBaseURL() {
    const protocol = this.host.includes("localhost") ? "http://" : "https://";
    if (this.isKNN) {
      return `${protocol}${this.host}/login`;
    }
    return `${protocol}${this.host}/login`;
  }

  get loginURL() {
    return (
      this.loginBaseURL +
      `?email=${this.invoiceAluno.email}&password=${this.invoiceAluno.cpf.slice(
        0,
        4
      )}`
    );
  }

  get isKNN() {
    return this.url.includes("knnidiomas") || this.url.includes("localhost");
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get franchisingName() {
    return this.isKNN ? "KNN" : "Phenom";
  }

  get lastInvoice() {
    return invoiceStore.lastInvoice;
  }

  formatPhone(value: string) {
    try {
      if (!value) return;
      switch (value.length) {
        case 11:
          return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(
            7
          )}`;
        case 10:
          return `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(
            6
          )}`;
        case 9:
          return `${value.slice(0, 5)}-${value.slice(5)}`;
        case 8:
          return `${value.slice(0, 4)}-${value.slice(4)}`;
        default:
          return " - ";
      }
    } catch {
      return " - ";
    }
  }

  closeSection() {
    localStorage.clear();
    this.$router.push("/");
    invoiceStore.clearPedido();
  }

  async checkLoggedIn() {
    // await invoiceAlunoStore.checkLoggedIn();
    if (!this.invoiceAluno) {
      if (this.$router.currentRoute.path !== "/login") {
        this.$router.push("/login");
      }
    } else if (this.$router.currentRoute.path !== "/payment") {
      this.$router.push("/payment");
    }
  }

  formatCPF(value: string) {
    try {
      if (!value) return " - ";
      const val = value.replace(/\D+/g, "");
      if (val.length !== 11) return " - ";
      return `${val.slice(0, 3)}.${val.slice(3, 6)}.${val.slice(
        6,
        9
      )}-${val.slice(9)}`;
    } catch {
      return " - ";
    }
  }
}
