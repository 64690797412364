import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import api from "@/api"
import _ from 'lodash'
import { IInvoiceAluno } from '@/interfaces/invoiceAluno'
import { invoiceStore } from '..'


@Module({ name: "invoiceAluno" })
export default class InvoiceAlunoModule extends VuexModule {

  invoiceAluno: IInvoiceAluno | null = null

  @Mutation
  setInvoiceAluno(payload: IInvoiceAluno) {
    this.invoiceAluno = payload
  }


  @Action
  async checkLoggedIn() {
    if (!this.invoiceAluno) {
      const email = localStorage.getItem("invoice-login")
      const password = localStorage.getItem("invoice-password")
      if (email && password) {
        await this.getInvoiceAluno({ email: email, password: password })
      }
    }
  }

  @Action
  async getInvoiceAluno(payload: { email: string, password: string }) {
    try {
      const response = await api.getInvoiceAluno(payload)
      const data: any = response.data
      if (!data) throw new Error("Erro")

      const { pre_invoices } = data
      invoiceStore.setInvoices(_.filter(pre_invoices, (p) => p.status_id > 0))
      this.setInvoiceAluno(data)

      localStorage.setItem("invoice-login", payload.email);
      localStorage.setItem("invoice-password", payload.password);

      return { isOk: true, msg: "Cadastro encontrado com sucesso" }
    } catch (e) {
      return { isOk: false, msg: "Cadastro não encontrado" }
    }
  }
}