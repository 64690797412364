
import Mixins from "@/Mixins.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { formatDistanceStrict, isBefore, parseISO } from "date-fns";
import { pt } from "date-fns/locale";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    VueQrcode,
  },
})
export default class Cartao extends Mixins {
  @Prop({ type: String }) readonly value!: string;

  maxiPagoBaseURL = "https://secure.maxipago.net/maxipay/#!/fp?p=";
  showSnackbar = false;

  get contagemRegressivaVencimentoPix() {
    if (!(this.lastInvoice && this.lastInvoice.pix_code_expiration)) return;

    return formatDistanceStrict(
      parseISO(this.lastInvoice.pix_code_expiration),
      new Date(),
      {
        addSuffix: false,
        locale: pt,
      }
    );
  }

  get pixExpired() {
    if (!(this.lastInvoice && this.lastInvoice.pix_code_expiration)) {
      return true;
    }
    return isBefore(parseISO(this.lastInvoice.pix_code_expiration), new Date());
  }

  async clipboardCopy(value: any) {
    this.showSnackbar = true;
    if (!!navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(value);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = value;
      textArea.style.position = "fixed";
      // textArea.style.left = "-999999px";
      // textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      await new Promise<void>((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }
}
