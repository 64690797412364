import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=620b5ccf&scoped=true"
import script from "./LoginForm.vue?vue&type=script&lang=ts"
export * from "./LoginForm.vue?vue&type=script&lang=ts"
import style0 from "./LoginForm.vue?vue&type=style&index=0&id=620b5ccf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620b5ccf",
  null
  
)

export default component.exports