import api from "@/api";
import { ICreditCard } from "@/interfaces/creditCard";
import { IFormasPagamento } from "@/interfaces/formasPagamento";
import { IPreInvoice } from "@/interfaces/preInvoice";
import _ from "lodash";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ name: "invoice" })
export default class InvoiceModule extends VuexModule {

  invoices: IPreInvoice[] = []
  // invoice: IPreInvoice = null;

  get lastInvoice() {
    return _.first(_.orderBy(this.invoices, ["created_at", "status_id"], ["desc", "asc"])) || null
  }

  formasPagamento: IFormasPagamento = {
    0: null,
    1: null,
    11: null,
  }

  // @Mutation
  // setInvoice(payload: IPreInvoice) {
  //   this.invoice = payload;
  // }

  @Mutation
  setInvoices(payload: IPreInvoice[]) {
    this.invoices = payload;
  }

  @Mutation
  setFormasPagamento(payload: IFormasPagamento) {
    this.formasPagamento = payload;
  }

  @Mutation
  clearPedido() {
    // this.invoice = null;
    this.invoices = []
  }

  @Action
  createFrontendInvoice(payload: IPreInvoice) {
    // this.setInvoice(payload)
    this.setInvoices([payload])
  }

  @Action
  async submitPayment(payload: { creditCard: ICreditCard, couponCode?: string }) {
    try {
      const response = await api.submitPayment(payload);

      if (response.data && response.status >= 200 && response.status < 300) {
        this.setInvoices([_.get(response.data, "pre_invoice")]);
        this.setFormasPagamento(_.get(response.data, "formas_pagamento"));

        return { confirmed: true, data: response.data };
      } else {
        return { confirmed: false, data: response.data };
      }
    } catch (e) {
      const error: any = e;
      return { confirmed: false, data: error.response.data.detail || "Não foi possível confirmar seu pagamento" };
    }
  }

  @Action
  async getFormasPagamento(payload: { preInvoiceId?: number, company_id?: number, couponCode?: string, c015_ids?: object }) {
    try {
      const response = await api.getFormasPagamento(payload);

      if (response.data && response.status >= 200 && response.status < 300) {
        this.setFormasPagamento(response.data);
        return { confirmed: true, data: response.data };
      } else {
        return { confirmed: false, data: response.data };
      }
    } catch (e) {
      const error: any = e;
      return { confirmed: false, data: error.response.data.detail };
    }
  }

  @Action
  async verifyCoupon(payload: { couponCode: string, companyId: number }) {
    try {
      const response = await api.verifyCoupon(payload);
      return { confirmed: true, data: response.data }
    } catch (e) {
      const error: any = e;
      return { confirmed: false, data: error.response.data.detail };
    }
  }
}
