
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BookCover from "@/components/BookCover.vue";
import { IPreInvoiceItem } from "@/interfaces/preInvoice";
import _ from "lodash";

@Component({
  components: { BookCover },
})
export default class ProductDetails extends Vue {
  @Prop() readonly preInvoiceItem!: IPreInvoiceItem;
  @Prop({ default: 80 }) readonly size!: number;
  @Prop({ default: false }) readonly portalAluno!: boolean;

  get title() {
    if (this.preInvoiceItem.quantity === 1) return "1 Livro";
    return `${this.preInvoiceItem.quantity} Livros`;
  }

  get productName() {
    let name = _.map(
      _.split(this.preInvoiceItem.product.item_name, " "),
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    )
      .slice(1)
      .join(" ");

    if (this.preInvoiceItem.quantity > 1 && this.portalAluno) {
      name += "\ncom desconto";
    }

    return name;
  }
}
